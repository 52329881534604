import React, { RefObject, useEffect, useState } from "react";
import { ListPaymentMethodsProps } from "../../components/ListPaymentMethods/ListPaymentMethods.interface";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Control, DeepMap, FieldError, useForm } from "react-hook-form";
import { MainProps } from "../Main";
import { useHistory, useParams } from "react-router-dom";
import {
  defaultTo,
  get,
  has,
  isEmpty,
  isNil,
  set,
  toLower,
  forEach,
} from "lodash";
import visa from "../../assets/logos/visa.svg";
import mastercard from "../../assets/logos/Mastercard.svg";
import diners from "../../assets/logos/Diners.svg";
import discover from "../../assets/logos/Discover.svg";
import amex from "../../assets/logos/amex.svg";
import transfer from "../../assets/logos/transfer.svg";
import pse from "../../assets/logos/pse.svg";
import cash from "../../assets/logos/cash.svg";
import carnet from "../../assets/logos/Carnet.svg";
import jcb from "../../assets/logos/jcb.svg";
import alia from "../../assets/logos/alia.svg";
import type { IDeferredResponse } from "@kushki/js/lib/types/remote/deferred_response";
import type { TokenRequest } from "@kushki/js/lib/types/token_request";
import { CurrencyEnum } from "../../shared/infrastructure/CurrencyEnum";
import type { TokenResponse } from "@kushki/js/lib/types/remote/token_response";
import type { ErrorResponse } from "@kushki/js/lib/types/error_response";
import { CaptureOtpProps } from "../../components/CaptureOtp/CaptureOtp";
import type { SecureOtpRequest } from "@kushki/js/lib/types/secure_otp_request";
import type { SecureOtpResponse } from "@kushki/js/lib/types/secure_otp_response";
import { ChargesRequest } from "../../../types/charges_request";
import { IdentificationNumberEnum } from "../../shared/infrastructure/IdentificationNumberEnum";
import type { CashTokenRequest } from "@kushki/js/lib/types/cash_token_request";
import type { TransferTokenRequest } from "@kushki/js/lib/types/remote/transfer_token_request";
import { SuccessCharge } from "../../../types/success_charge";
import { ProcessorsEnum } from "../../shared/infrastructure/ProcessorEnum";
import { CountryEnum } from "../../shared/infrastructure/CountryEnum";
import { BankItem } from "../../../types/bank_list";
import {
  Amount,
  IAdditionalInfo,
  Product,
  Smartlink,
  Webcheckout,
  ExpressCheckout,
} from "../../../types/webcheckout";
import { format } from "date-fns";
import { ConfirmMessageProps } from "../../components/commons/ConfirmMessage/ConfirmMessage";
import {
  ICapturePasswordless,
  useAuth,
} from "../../shared/service/AuthService";
import { GA_CONSTANTS } from "../../shared/constants/GAConstants";
import {
  initializeGA,
  setGAEvent,
} from "../../shared/service/GoogleAnalyticsService";
import {
  CardPaymentDataResponse,
  CashPaymentDataResponse,
  PaymentDataResponse,
  TransferPaymentDataResponse,
} from "../../../types/payment_data_response";
import { PaymentDataKindEnum } from "../../shared/infrastructure/PaymentDataKindEnum";
import type { TokenKPayRequest } from "@kushki/js/lib/types/token_kpay_request";
import { EventTypeEnum } from "../../shared/infrastructure/EventTypeEnum";
import { ChannelEnum } from "../../shared/infrastructure/ChannelEnum";
import { getImagesByCountryAndProcessor } from "../../shared/constants/AllBrandsCash";
import { TransactionTypeEnum } from "../../shared/infrastructure/TransactionTypeEnum";
import type { Validate3DsResponse } from "@kushki/js/lib/types/validate_3ds_response";
import {
  LanguagesEnum,
  TEXT_ENUM_LANGUAGE,
} from "../../shared/infrastructure/DefaultTextButton";
import { ISiftscienceForm } from "../../components/CardFormCard/state/interfaces/ISiftScienceForm";
import { ISiftForm } from "../../components/CardFormCard/state/interfaces/ISiftForm";
import type { TransferSubscriptionTokenRequest } from "@kushki/js/lib/types/transfer_subscription_token_request";
import { Translate as T } from "react-localize-redux";
import {
  ICommission,
  IProduct,
} from "../../components/PurchaseDetail/PurchaseDetail.interfaces";
import type { CardAsyncTokenRequest } from "@kushki/js/lib/types/card_async_token_request";
import type { CardAsyncTokenResponse } from "@kushki/js/lib/types/card_async_token_response";
import { GetBrandsLogosByMerchantResponse } from "../../../types/get_brands_logos_by_merchant_response";
import { useRollbar } from "@rollbar/react";
import { KInfo } from "../../KushkiInfo";
import { formatAmount } from "../../components/PurchaseDetail/PurchaseDetail";
import { AmountsTransaction } from "../../../types/amouts_transaction";
import { CountryCodeCatalog } from "../../shared/service/CountryCatalog";
import { CardBrandsEnum } from "@kushki/js/lib/infrastructure/CardBrandsEnum";
import { WebcheckoutTypeEnum } from "../../shared/infrastructure/WebcheckoutTypeEnum";
import { SiftscienceFieldsEnum } from "../../shared/infrastructure/SiftscienceFieldsEnum";
import { ProcessorsNameEnum } from "../../shared/infrastructure/ProcessorsNameEnum";

export interface IProcessorCash {
  processorName: string;
}

export interface UseMainState {
  isMobile: boolean;
  paymentMethod: ListPaymentMethodsProps;
  paymentMethodForm: string;
  subtotal: number;
  openTerms: boolean;
  buttonText: string;
  disablePay: boolean;
  transferForm: {
    identificationType: {
      value: string;
      label: string;
    }[];
  };
  openSavedCardForm: boolean;
  handler: {
    handleChangePaymentMethod: () => void;
    handleOpenTermsConditions: () => void;
    handleSetFields: (kind: string, value?: string) => void;
    handleChangeTab: (event: React.ChangeEvent<{}>, newValue: number) => void;
    handleOpenCollapse: () => void;
    handleRetryPayment: () => void;
    handleAcceptTerms: () => void;
    handleSaveMethod: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };
  form: {
    register: (
      obj: object
    ) => ((instance: any) => void) | RefObject<any> | null | undefined;
    errors: DeepMap<Record<string, any>, FieldError>;
    handleSubmit: any;
    handleOnSubmitForm: () => void;
    control: Control<Record<string, any>>;
    getValues: (field: string) => any;
    reset: ({}: any) => any;
    currentValues: {
      months: string;
      monthsOfGrace: string;
      typeDeferred: string;
    };
  };
  webcheckout: {
    redirectURL: string;
    country: string;
    transferProcessorName: string;
    totalAmount: number;
    configuration?: {
      buttonShape: string;
      buttonText?: string;
      image?: string;
      language?: string;
      mainColor?: string;
      metadata?: {};
      publicMerchantId: string;
      secondColor?: number;
      showAmount?: number;
    };
    paymentConfig?: IPaymentConfig;
    paymentDescription: string;
    webcheckoutType: string;
    logos?: {
      cardBrands: { image: string; value: string }[];
      transferBrands: { image: string; value: string }[];
      cashBrands: { image: string; value: string }[];
    };
    brandCardInput?: string;
    cardType?: string;
    products: IProduct[];
    color: string;
    secondaryColor: string;
    buttonStyle: string;
    commission?: ICommission;
    metadata?: IAdditionalInfo[];
  };
  deferredOptions?: {
    hasDeferred?: boolean;
    isDynamic?: boolean;
    typesDeferred?: SelectOption[];
    months?: SelectOption[];
    monthsOfGrace?: SelectOption[];
    isActive?: boolean;
  };
  selectTab: number;
  openCollapse: boolean;
  captureOtp?: CaptureOtpProps;
  capturePasswordless?: ICapturePasswordless;
  userEmail: string;
  userName: string;
  cardSavedPaymentData: CardPaymentDataResponse;
  cashSavedPaymentData: CashPaymentDataResponse;
  transferSavedPaymentData: TransferPaymentDataResponse;
  confirmMessage: ConfirmMessageProps;
  eventType?: string;
  siftscienceForm?: ISiftscienceForm;
  savedCardId?: string;
  isValidDate: (expDate: string) => boolean;
  isValidCVV: (cvv: string) => boolean;
  isLoadingProps: boolean;
  showCardBrandingAnimation?: boolean;
  showMonthlyPaymentMessage?: boolean;
}

export const allBrandsCard: { image: string; value: string }[] = [
  { image: visa, value: "visa" },
  { image: mastercard, value: "masterCard" },
  { image: jcb, value: "jcb" },
  { image: diners, value: "diners" },
  { image: discover, value: "discover" },
  { image: amex, value: "amex" },
  { image: carnet, value: "carnet" },
  { image: alia, value: "alia" },
];

export const defaultBrandsCard: { image: string; value: string }[] = [
  { image: visa, value: "visa" },
  { image: mastercard, value: "mastercard" },
];
export const defaultBrandsTransfer: { image: string; value: string }[] = [
  { image: transfer, value: "transfer" },
];
export const defaultBrandsCash: { image: string; value: string }[] = [
  { image: cash, value: "cash" },
];

export type SelectOption = {
  label: string;
  value: string;
};

export type ParamsTransfer = {
  amount: number | string;
  currency: string;
  merchantName: string;
  customerName: string;
  email: string;
  bankName?: string;
  date: string;
  documentNumber: string;
  products: Product[];
  redirectUrl: string;
  id: string;
  transactionType?: string;
};

export interface ISmartLinkAmount {
  subtotalIva: number;
  subtotalIva0: number;
  iva: number;
  ice?: number;
  currency:
    | "USD"
    | "COP"
    | "PEN"
    | "CLP"
    | "UF"
    | "MXN"
    | "BRL"
    | "CRC"
    | "GTQ"
    | "HNL"
    | "NIO"
    | "PAB";

  [k: string]: any;
}

export type CatalogOptions = { [k: string]: SelectOption[] };

export const buildSiftScienceData = (
  siftScienceDetails?: ISiftForm,
  webcheckout?: Smartlink | Webcheckout | ExpressCheckout
) => {
  if (!isNil(siftScienceDetails) && !isEmpty(siftScienceDetails))
    return siftScienceDetails;

  const siftScienceData = {};

  forEach(SiftscienceFieldsEnum, (attribute: string) => {
    const value = get(webcheckout, `billingDetails.${attribute}`, undefined);

    if (!isNil(value)) set(siftScienceData, attribute, value);
  });

  return !isEmpty(siftScienceData) ? siftScienceData : undefined;
};

export const buildChargeRequest = (
  token: TokenResponse,
  deferred: {
    months: string;
    monthsOfGrace: string;
    typeDeferred: string;
    isActive: boolean;
  },
  savePaymentData: boolean,
  webcheckout?: Smartlink | Webcheckout | ExpressCheckout,
  paymentDataEmail?: string,
  emailContactDetail?: string,
  siftScienceDetails?: ISiftForm,
  walletId?: string,
  webcheckoutEmail?: string,
  paymentMethod?: string
): ChargesRequest => {
  const siftScienceData = buildSiftScienceData(siftScienceDetails, webcheckout);

  const charge: ChargesRequest = {
    token: get(token, "token", ""),
    metadata: buildMetadata(webcheckout),
    activationMethod:
      !isEmpty(paymentMethod) && paymentMethod === "cardAsync"
        ? "cardAsyncPayment"
        : "singlePayment",
    transactionType: get(webcheckout, "transactionType", "SALE"),
    paymentDataEmail,
    webcheckoutEmail,
    ...(siftScienceData !== undefined && {
      siftScienceDetails: siftScienceData,
    }),
    ...(walletId !== undefined && { walletId }),
    channel: getChannel(webcheckout),
  };

  if (savePaymentData) charge.paymentDataEmail = webcheckoutEmail;

  if (deferred.isActive && deferred.typeDeferred) {
    charge.deferred = {
      creditType: deferred.typeDeferred,
      graceMonths: deferred.monthsOfGrace || "0",
      months: Number(deferred.months),
    };
  }

  if (deferred.isActive && deferred.months) {
    charge.months = deferred.months;
  }

  if (!isEmpty(get(webcheckout, "contactDetail"))) {
    charge.contactDetails = {
      documentNumber: get(webcheckout, "contactDetail.documentNumber"),
      documentType: get(webcheckout, "contactDetail.documentType"),
      phoneNumber: get(webcheckout, "contactDetail.phoneNumber"),
    };
  }

  if (!isEmpty(webcheckoutEmail)) {
    charge.contactDetails = {
      ...charge.contactDetails,
      email: webcheckoutEmail,
    };
  }

  charge.channel = getChannel(webcheckout);

  return charge;
};

export const getChannel = (
  webcheckout?: Smartlink | Webcheckout | ExpressCheckout
): ChannelEnum => {
  const source = get(webcheckout, "kushkiMetadata.source", "").toUpperCase();

  if (source === WebcheckoutTypeEnum.EXPRESS_CHECKOUT.toUpperCase())
    return ChannelEnum.WEBCHECKOUTEXPRESS;

  return defaultTo(
    Object.values(ChannelEnum).find((channel) => channel === source),
    ChannelEnum.WEBCHECKOUTPLUS
  );
};

const buildMetadata = (
  webCheckout?: Smartlink | Webcheckout | ExpressCheckout
) => {
  const attributesAdditionalInfo = get(webCheckout, "additional_info", []).map(
    (item: IAdditionalInfo) => item.attribute
  );
  const isExpressCheckout =
    get(webCheckout, "kushkiMetadata.source", "") ===
    WebcheckoutTypeEnum.EXPRESS_CHECKOUT;
  const additionalInfo = get(webCheckout, "additionalInformation", {});
  const additionalInfoFiltered = Object.fromEntries(
    Object.entries(additionalInfo).filter(([key]) =>
      attributesAdditionalInfo.includes(key)
    )
  );

  if (!isEmpty(get(webCheckout, "additionalInformation")))
    return additionalInfoFiltered;

  if (isExpressCheckout)
    return {
      name: get(webCheckout, "contactDetail.name", ""),
      email: get(webCheckout, "contactDetail.email", ""),
      paymentDescription: get(webCheckout, "paymentDescription", ""),
    };

  return {};
};

export interface ISavedPaymentData {
  savedCardPaymentData?: CardPaymentDataResponse[];
  savedCashPaymentData?: CashPaymentDataResponse[];
  savedTransferPaymentData?: TransferPaymentDataResponse[];
}

export interface IPaymentConfig {
  amount: Amount;
  paymentMethod:
    | (
        | "credit-card"
        | "transfer"
        | "subscription"
        | "card-dynamic"
        | "card-async"
        | "cash"
        | "transfer-subscription"
      )
    | string[];
}

export const buildCallbackUrl = (
  processorName: string,
  data: ParamsTransfer,
  kushkiMetadataSource: string,
  paymentMethod?: string
): URL => {
  const callback_url: URL = new URL(
    `${window.location.origin}/webcheckout/resume`
  );
  callback_url.searchParams.append("amount", data.amount.toString());
  callback_url.searchParams.append("currency", data.currency);
  callback_url.searchParams.append("merchantName", data.merchantName);
  callback_url.searchParams.append("customerName", data.customerName);
  callback_url.searchParams.append("date", data.date);
  callback_url.searchParams.append("email", data.email);
  callback_url.searchParams.append("documentNumber", data.documentNumber);
  callback_url.searchParams.append("redirectUrl", data.redirectUrl);
  callback_url.searchParams.append("id", data.id);
  callback_url.searchParams.append(
    "products",
    JSON.stringify(get(data, "products", []))
  );
  callback_url.searchParams.append(
    "transactionType",
    get(data, "transactionType", "")
  );

  if (paymentMethod === "cardAsync") {
    callback_url.searchParams.append("totalAmount", data.amount.toString());
    callback_url.searchParams.append("kind", "cardAsync");
    callback_url.searchParams.append("resumeType", "cardAsync");
    callback_url.searchParams.append("kindWebcheckout", kushkiMetadataSource);
  } else {
    callback_url.searchParams.append("resumeType", "transfer");
    callback_url.searchParams.append("kindWebcheckout", kushkiMetadataSource);
    if (processorName === ProcessorsEnum.PSE) {
      callback_url.searchParams.append("kind", "pse");
      callback_url.searchParams.append("bankName", get(data, "bankName", ""));
    } else {
      callback_url.searchParams.append("kind", "transfer");
    }
  }

  return callback_url;
};

interface IParams {
  smartlinkId: string;
}

export const useMainState = (props: MainProps): UseMainState => {
  const rollbar = useRollbar();
  const theme = useTheme();
  const history = useHistory();
  const params = useParams<IParams>();
  const {
    capturePasswordless,
    checkSession,
    initAuth,
    hidePasswordless,
  } = useAuth();
  const { register, errors, handleSubmit, control, reset, getValues } = useForm(
    {
      shouldUnregister: false,
    }
  );
  const siftscienceForm = useForm<ISiftForm>({
    shouldUnregister: false,
  });
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [paymentMethodForm, setPaymentMethodForm] = useState<string>("");
  const [showOptionMethod, setShowOptionMethod] = useState<boolean>(true);
  const [openTerms, setOpenTerms] = useState<boolean>(false);
  const [openSiftscienceForm, setOpenSiftscienceForm] = useState<boolean>(
    false
  );
  const [savedCardId, setSavedcardId] = useState<string>("");
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [currentBin, setCurrentBin] = useState<string>("");
  const [hasDeferred, setHasDeferred] = useState<boolean>(false);
  const [isDynamic, setIsDynamic] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>("");
  const [typesDeferred, setTypeDeferred] = useState<SelectOption[]>([]);
  const [months, setMonths] = useState<SelectOption[]>([]);
  const [monthsOfGrace, setMonthsOfGrace] = useState<SelectOption[]>([]);
  const [methodsActive, setMethodsActive] = useState<string[]>([]);
  const [currency, setCurrency] = useState<string>("USD");
  const [showMonthlyPaymentMessage, setShowMonthlyPaymentMessage] = useState<
    boolean | undefined
  >(undefined);
  const [color, setColor] = useState<string>("#CC9200");
  const [secondaryColor, setSecondaryColor] = useState<string>("#CC9200");
  const [buttonStyle, setButtonStyle] = useState<string>("#CC9200");
  const [brandCardInput, setBrandCardInput] = useState<string | undefined>(
    undefined
  );
  const [cardType, setCardType] = useState<string | undefined>(undefined);
  const [merchantId, setMerchantId] = useState<string>("");
  const [brandsCard, setBrandsCard] = useState<
    {
      image: string;
      value: string;
    }[]
  >(defaultBrandsCard);
  const [brandsTransfer, setBrandsTransfer] = useState<
    {
      image: string;
      value: string;
    }[]
  >(defaultBrandsTransfer);
  const [brandsCash, setBrandsCash] = useState<
    {
      image: string;
      value: string;
    }[]
  >(defaultBrandsCash);
  const [currentValues, setCurrentValues] = useState<{
    months: string;
    monthsOfGrace: string;
    typeDeferred: string;
  }>({
    months: "",
    monthsOfGrace: "",
    typeDeferred: "",
  });
  const [commission, setCommission] = useState<ICommission | undefined>(
    undefined
  );
  const [selectTab, setSelectTab] = useState<number>(0);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [subtotal, setSubtotal] = useState<number>(0);
  const [metadata, setMetadata] = useState<IAdditionalInfo[]>([]);
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [token, setToken] = useState<TokenResponse>();
  const [otpInvalid, setOtpInvalid] = useState<boolean>(false);
  const [otpAttempts, setOtpAttempts] = useState<number>(0);
  const [userEmail, setUserEmail] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [disablePay, setDisablePay] = useState<boolean>(true);
  const [openOtpError, setOtpError] = useState<boolean>(false);
  const [identificationNumberType, setIdentificationNumberType] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [country, setCountry] = useState<string>("");
  const [transferProcessorName, setTransferProcessorName] = useState<string>(
    ""
  );
  const [openSavedCardForm, setOpenSavedCardForm] = useState<boolean>(false);
  const [cardSavedPaymentData, setCardSavedPaymentData] = useState<
    CardPaymentDataResponse
  >({
    cardHolderName: "",
    lastFourDigits: "",
    brand: "visa",
    kind: "card",
    expDate: "",
    id: "",
    email: "",
    maskedCardNumber: "",
  });

  const [transferSavedPaymentData, setTransferSavedPaymentData] = useState<
    TransferPaymentDataResponse
  >({
    id: "",
    bankId: "",
    documentNumber: "",
    documentType: "",
    email: "",
    kind: "transfer",
    name: "",
    userType: "",
  });

  const [cashSavedPaymentData, setCashSavedPaymentData] = useState<
    CashPaymentDataResponse
  >({
    id: "",
    email: "",
    name: "",
    documentType: "",
    documentNumber: "",
    kind: "cash",
  });

  const [paymentData, setPaymentData] = useState<ISavedPaymentData>({});
  const [savedSubscription, setSavedSubscription] = useState<boolean>(false);
  const [paymentConfig, setPaymentConfig] = useState<IPaymentConfig>({
    amount: {
      subtotalIva: 0,
      subtotalIva0: 0,
      iva: 0,
      currency: "USD",
    },
    paymentMethod: "credit-card",
  });
  const [openConfirmMessage, setOpenConfirmMessage] = useState<boolean>(false);
  const [payMethodIdSelected, setPayMethodIdSelected] = useState<
    string | undefined
  >(undefined);
  const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false);
  const [walletId, setWalletId] = useState<string>("");
  const [eventType, setEventType] = useState<string>("");
  const [showOTP, setShowOTP] = useState<boolean>(has(token, "secureId"));
  const [isLoadingProps, setIsLoadingProps] = useState<boolean>(true);
  const [
    labelCreditCardPaymentMethod,
    setLabelCreditCardPaymentMethod,
  ] = useState<string>("");
  const [showCardBrandingAnimation, setShowCardBrandingAnimation] = useState<
    boolean
  >(false);
  const [paymentDescription, setPaymentDescription] = useState<string>("");
  const [webcheckoutType, setWebcheckoutType] = useState<string>("");

  const getTotalAmount = (amount?: ISmartLinkAmount) => {
    if (!amount) return 0;
    return amount.subtotalIva + amount.subtotalIva0 + amount.iva;
  };

  const checkCardTitlePaymentMethod = (country: string) => {
    if (country === CountryEnum.CHL) {
      const paymentMethod = props.webcheckout?.paymentConfig.paymentMethod;
      const hasCreditCard =
        paymentMethod && paymentMethod.includes("credit-card");
      const hasCardAsync =
        paymentMethod && paymentMethod.includes("card-async");
      if (hasCreditCard && !hasCardAsync) {
        setLabelCreditCardPaymentMethod("listPaymentMethods.titleCardAndDebit");
        return;
      }
      if (hasCreditCard && hasCardAsync) {
        setLabelCreditCardPaymentMethod("listPaymentMethods.titleCard");
        return;
      }
      setLabelCreditCardPaymentMethod("listPaymentMethods.titleCard");
    } else if (country !== CountryEnum.CHL)
      setLabelCreditCardPaymentMethod("listPaymentMethods.titleCardAndDebit");
  };

  const kushkiProcessorFilter = () => {
    return get(props, "settings.processors.card", []).filter(
      (processor: { processorName: string }) =>
        get(processor, "processorName", "") ===
        ProcessorsNameEnum.KUSHKI_PROCESSOR
    );
  };

  useEffect(() => {
    const country = get(props, "webcheckout.country", null);
    if (!Boolean(country)) setLabelCreditCardPaymentMethod("");
    else checkCardTitlePaymentMethod(country);
  }, [props.webcheckout?.country]);

  useEffect(() => {
    const billingDetails = get(props.webcheckout, "billingDetails");

    if (billingDetails) {
      siftscienceForm.setValue(
        "firstName",
        get(billingDetails, "firstName", "")
      );
      siftscienceForm.setValue("email", get(billingDetails, "email", ""));
      siftscienceForm.setValue("lastName", get(billingDetails, "lastName", ""));
      siftscienceForm.setValue("address", get(billingDetails, "address", ""));
      siftscienceForm.setValue(
        "secondaryAddress",
        get(billingDetails, "secondaryAddress", "")
      );
      siftscienceForm.setValue("city", get(billingDetails, "city", ""));
      siftscienceForm.setValue("zipCode", get(billingDetails, "zipCode", ""));
      siftscienceForm.setValue("country", get(billingDetails, "country", ""));
      siftscienceForm.setValue("region", get(billingDetails, "province", ""));
      siftscienceForm.setValue(
        "countryCode",
        defaultTo(CountryCodeCatalog[get(billingDetails, "country", "")], "")
      );
    }
  }, [props.webcheckout]);

  useEffect(() => {
    const transferProcessors = get(props, "settings.processors.transfer", []);
    const hasKushkiProcessor: boolean = !isEmpty(kushkiProcessorFilter());
    const isCountryChile: boolean =
      get(props, "settings.country", "") === CountryEnum.CHL;

    if (!!transferProcessors && transferProcessors.length > 0) {
      const transferProcessorName = transferProcessors[0].processorName;
      setTransferProcessorName(transferProcessorName);
      if (transferProcessorName === ProcessorsEnum.PSE) {
        setBrandsTransfer([
          {
            image: pse,
            value: "pse",
          },
        ]);
      }
    }

    if (isCountryChile && hasKushkiProcessor)
      setLabelCreditCardPaymentMethod("listPaymentMethods.titleCardAndDebit");

    if (!!props.settings) setIsLoadingProps(false);
  }, [props.settings]);

  useEffect(() => {
    setLoadingConfirm(false);
    setOpenConfirmMessage(false);
    if (!props.savedPaymentMethods || props.savedPaymentMethods.length === 0) {
      setPaymentData({
        savedCardPaymentData: [],
        savedTransferPaymentData: [],
        savedCashPaymentData: [],
      });

      return;
    }

    const savedCardPaymentData: CardPaymentDataResponse[] = props.savedPaymentMethods.filter(
      (item): item is CardPaymentDataResponse =>
        item.kind === PaymentDataKindEnum.card
    );

    const savedTransferPaymentData: TransferPaymentDataResponse[] = props.savedPaymentMethods.filter(
      (item: PaymentDataResponse): item is TransferPaymentDataResponse =>
        item.kind === PaymentDataKindEnum.transfer
    );

    const savedCashPaymentData: CashPaymentDataResponse[] = props.savedPaymentMethods.filter(
      (item: PaymentDataResponse): item is CashPaymentDataResponse =>
        item.kind === PaymentDataKindEnum.cash
    );

    setPaymentData({
      savedCardPaymentData,
      savedTransferPaymentData,
      savedCashPaymentData,
    });
  }, [props.savedPaymentMethods]);

  useEffect(() => {
    const smartLinkId: string = params.smartlinkId;

    if (smartLinkId) props.getWebcheckout(smartLinkId);

    initializeGA();
  }, []);
  useEffect(() => {
    if (props.link) {
      const smartLinkId: string = get(params, "smartlinkId");
      const error: string = props.link.link;
      history.push(`/webcheckout/${smartLinkId}/${error}`);
    }
  }, [props.link]);
  useEffect(() => {
    if (!isEmpty(props.webcheckout)) {
      KInfo.kushkiInfo = KInfo.buildKushkiInfo(
        get(props.webcheckout, "kushkiMetadata.source", undefined)
      );
      const publicMerchantId: string = get(
        props.webcheckout,
        "publicMerchantId",
        ""
      );

      if (props.webcheckout?.executionCount! > 0)
        history.push("/webcheckout/expired-session");

      const currency: string = get(
        props.webcheckout!.paymentConfig.amount,
        "currency",
        CurrencyEnum[get(props.webcheckout, "country", "Ecuador")]
      );

      setButtonText(
        get(
          props.webcheckout!.configuration,
          "buttonText",
          TEXT_ENUM_LANGUAGE[
            get(props.webcheckout!.configuration, "language", LanguagesEnum.ES)
          ]
        )
      );
      setShowMonthlyPaymentMessage(
        get(props.webcheckout, "configuration.showMonthlyPaymentMessage", false)
      );
      setPaymentConfig(get(props.webcheckout!, "paymentConfig"));
      setTotalAmount(
        getTotalAmount(get(props.webcheckout, "paymentConfig.amount"))
      );
      setMethodsActive(
        get(props.webcheckout, "paymentConfig.paymentMethod", "credit-card")
      );
      setCurrency(currency);
      setColor(
        get(props.webcheckout, "styleAndStructure.primaryColor", "#CC9200")
      );
      setSecondaryColor(
        get(props.webcheckout, "styleAndStructure.secondaryColor", "#CC9200")
      );
      setButtonStyle(
        get(props.webcheckout, "styleAndStructure.buttonStyle", "#CC9200")
      );
      setProducts(get(props.webcheckout, "products", []));
      setSubtotal(
        get(props.webcheckout!.paymentConfig.amount, "subtotalIva", 0) +
          get(props.webcheckout!.paymentConfig.amount, "subtotalIva0", 0)
      );
      setMetadata(get(props.webcheckout, "additional_info", []));

      props.getListBrandsMerchant(publicMerchantId);
      props.getCommission(
        publicMerchantId,
        get(props.webcheckout, "totalAmount", 0),
        currency
      );
      setUserName(get(props.webcheckout, "contactDetail.name", ""));
      setCountry(get(props.webcheckout, "country", ""));
      setIdentificationNumberType(
        IdentificationNumberEnum[get(props.webcheckout, "country")]
      );

      setPaymentDescription(get(props.webcheckout, "paymentDescription", ""));
      setWebcheckoutType(get(props.webcheckout, "kushkiMetadata.source", ""));

      const webcheckoutEmail: string | undefined = get(
        props.webcheckout,
        "contactDetail.email"
      );
      checkSession((sessionEmail?: string) => {
        const email = sessionEmail || webcheckoutEmail;

        if (email) {
          const publicMerchantId = props.webcheckout
            ?.publicMerchantId as string;
          props.setWebcheckoutEmail(email);
          setUserEmail(email);
        } else {
          props.showSaveEmailModal();
        }
      }, webcheckoutEmail);
      props.setTimer({ endTime: props.webcheckout!.expirationSessionTime! });
      props.setActiveLanguage(
        toLower(get(props.webcheckout, "configuration.language", "es"))
      );

      rollbar.configure({
        payload: {
          person: {
            id: params.smartlinkId,
            username: get(props.webcheckout, "merchantName"),
            email: webcheckoutEmail,
          },
          custom: props.webcheckout,
        },
      });
    }
  }, [props.webcheckout]);

  useEffect(() => {
    if (!isEmpty(props.brandsList)) {
      const brandList: {
        image: string;
        value: string;
      }[] = props.brandsList!.map(
        (brand: GetBrandsLogosByMerchantResponse) => ({
          image: brand.url,
          value: brand.brand,
        })
      );

      setBrandsCard(brandList);
    }
  }, [props.brandsList]);

  useEffect(() => {
    if (!isEmpty(props.settings?.processors?.cash)) {
      const brandListCash: string[] = props.settings!.processors!.cash!.map(
        (processor: IProcessorCash) => {
          return processor.processorName;
        }
      );
      setBrandsCash(
        getImagesByCountryAndProcessor(props.settings!.country, brandListCash)
      );
    }
  }, [props.settings?.processors]);

  useEffect(() => {
    if (!isEmpty(props.brandCard)) {
      let brand: string = "";

      if (brandsCard) brand = filterBrandCard(brandsCard);
      if (isEmpty(brand)) brand = filterBrandCard(allBrandsCard);

      setBrandCardInput(brand);
      setCardType(props.brandCard?.cardType);
    }
  }, [props.brandCard]);

  useEffect(() => {
    if (!isEmpty(props.optionsFetched)) {
      const deferred: IDeferredResponse[] = props.optionsFetched!;
      const size: number = deferred.length;
      setHasDeferred(true);
      if (size === 1 && deferred[0].type === "all") {
        const months: SelectOption[] = deferred![0].months.map(
          (opt: string) => ({
            label: opt,
            value: opt,
          })
        );
        setMonths(months);
        setIsDynamic(false);
        setCurrentValues({
          ...currentValues,
          months: months[0].value,
        });

        return;
      }

      const typeDeferred: SelectOption[] = deferred!.map(
        (opt: IDeferredResponse) => ({
          label: opt.name!,
          value: opt.type,
        })
      );
      setIsDynamic(true);
      setTypeDeferred(typeDeferred);
      const months = reduceOptions(deferred, "months");
      setMonths(months[typeDeferred[0].value]);
      const monthsOfGrace = reduceOptions(deferred, "monthsOfGrace");
      setMonthsOfGrace(monthsOfGrace[typeDeferred[0].value]);
      setCurrentValues({
        monthsOfGrace: !isEmpty(monthsOfGrace[typeDeferred[0].value])
          ? monthsOfGrace[typeDeferred[0].value][0].value
          : "",
        months: months[typeDeferred[0].value][0].value,
        typeDeferred: typeDeferred[0].value,
      });
    } else setHasDeferred(false);
  }, [props.optionsFetched]);

  useEffect(() => {
    setCommission(props.commission);

    const totalCommission = get(props, "commission.totalAmount", 0);

    if (totalCommission > 0)
      setTotalAmount((currentAmount) => currentAmount + totalCommission);
  }, [props.commission]);

  useEffect(() => {
    if (cardSavedPaymentData.id === "") return;
    if (cardSavedPaymentData.kind === "card") {
      handleSetFields(cardSavedPaymentData.kind, "", true);
    }
  }, [cardSavedPaymentData]);

  useEffect(() => {
    if (props.userBlocked) {
      history.push(`/webcheckout/${props.webcheckout?.id}/wrong-otp`);
    }
  }, [props.userBlocked]);

  const reduceOptions = (
    deferred: IDeferredResponse[],
    kind: "months" | "monthsOfGrace"
  ) => {
    return deferred!.reduce(
      (acc: CatalogOptions, curr: IDeferredResponse) => ({
        ...acc,
        [`${curr.type}`]: [...curr[kind]].map((m: string) => ({
          label: m,
          value: m,
        })),
      }),
      {}
    );
  };

  const getGALabel = (value: string) => {
    let label: string;
    switch (value) {
      case "card":
        setGAEvent(
          GA_CONSTANTS.paymentMethods.category,
          GA_CONSTANTS.actions.click,
          GA_CONSTANTS.paymentMethods.labels.A
        );
        break;
      case "transfer":
        setGAEvent(
          GA_CONSTANTS.paymentMethods.category,
          GA_CONSTANTS.actions.click,
          GA_CONSTANTS.paymentMethods.labels.B
        );
        break;
      case "cash":
        setGAEvent(
          GA_CONSTANTS.paymentMethods.category,
          GA_CONSTANTS.actions.click,
          GA_CONSTANTS.paymentMethods.labels.C
        );
        break;
    }
  };

  const handleSelectMethod = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string
  ) => {
    getGALabel(value);
    setPaymentMethodForm(value);
    setShowOptionMethod(false);
  };

  const handlePaySavedMethod = async (
    kind: string,
    paymentMethod: PaymentDataResponse
  ) => {
    setEventType(EventTypeEnum.PAY);
    const handleSavedPaymentMethodCallback = () => {
      switch (kind) {
        case "card":
          setOpenSavedCardForm(true);
          setCardSavedPaymentData(paymentMethod as CardPaymentDataResponse);
          break;
        case "transfer":
          setPaymentMethodForm("transfer");
          setTransferSavedPaymentData(
            paymentMethod as TransferPaymentDataResponse
          );
          break;
        case "cash":
          setPaymentMethodForm("cash");
          setCashSavedPaymentData(paymentMethod as CashPaymentDataResponse);
          break;
        case "card-async":
          setPaymentMethodForm("cardAsync");
          setCardSavedPaymentData(paymentMethod as CardPaymentDataResponse);
          break;
      }
      setShowOptionMethod(false);
    };

    setWalletId(paymentMethod.id);

    if (kind === "card")
      await initAuth(
        {
          email: props.webcheckoutEmail!,
          merchantName: get(props.webcheckout, "merchantName"),
          firstName: get(props.webcheckout, "contactDetail.name"),
        },
        () => {
          setPaymentMethodForm("cardSaved");
          hidePasswordless();
          handleSavedPaymentMethodCallback();
        }
      );
    else handleSavedPaymentMethodCallback();
  };

  const handleChangePaymentMethod = () => {
    setGAEvent(
      GA_CONSTANTS.turnoverRate.category,
      GA_CONSTANTS.actions.click,
      GA_CONSTANTS.turnoverRate.labels.Z1
    );
    reset({});
    setPaymentMethodForm("");
    setDisablePay(true);
    setOpenSavedCardForm(false);
    setShowOptionMethod(true);
    setHasDeferred(false);
    setIsDynamic(false);
    setTypeDeferred([]);
    setMonths([]);
    setMonthsOfGrace([]);
    setIsActive(false);
    setCurrentBin("");
  };

  const handleOpenTermsConditions = () => {
    setOpenTerms(!openTerms);
  };

  const handleSetFields = (
    kind: string,
    value?: string,
    hasSavedMethod?: boolean
  ) => {
    if (kind === "card") {
      const publicMerchantId: string = get(
        props.webcheckout,
        "publicMerchantId",
        ""
      );
      const cardNumber: string = hasSavedMethod
        ? cardSavedPaymentData.maskedCardNumber
        : String(getValues("cardNumber")).replace(/\s/g, "");
      const newBin: string = cardNumber.substring(0, 8);

      if (cardNumber.trim().length >= 8 && currentBin !== newBin) {
        setCurrentBin(newBin);
        props.getCardBrand(publicMerchantId, cardNumber);
        if (
          get(props, "webcheckout.transactionType", "PRE_AUTH") !==
          TransactionTypeEnum.PRE_AUTH
        )
          props.getDeferredOptions(publicMerchantId, newBin);
      } else if (cardNumber.trim().length < 8) {
        setCurrentBin("");
        setBrandCardInput(undefined);
        setHasDeferred(false);
        setIsActive(false);
      }
    } else if (kind === "activeQuote") {
      setIsActive(!isActive);
    } else if (
      kind === "months" ||
      kind === "monthsOfGrace" ||
      kind === "typeDeferred"
    ) {
      if (kind === "typeDeferred") {
        const months = reduceOptions(props.optionsFetched!, "months");
        setMonths(months[value!]);
        const monthsOfGrace = reduceOptions(
          props.optionsFetched!,
          "monthsOfGrace"
        );
        setMonthsOfGrace(monthsOfGrace[value!]);
        setCurrentValues({
          monthsOfGrace: !isEmpty(monthsOfGrace[value!])
            ? monthsOfGrace[value!][0].value
            : "",
          months: months[value!][0].value,
          typeDeferred: value!,
        });

        return;
      }
      setCurrentValues({
        ...currentValues,
        [kind]: value!,
      });
    }
  };
  const getGAValues = () => {
    switch (paymentMethodForm) {
      case "card":
        setGAEvent(
          GA_CONSTANTS.dataForCreditCard.category,
          GA_CONSTANTS.actions.click,
          GA_CONSTANTS.dataForCreditCard.labels.A7
        );
        break;
      case "card-async":
        setGAEvent(
          GA_CONSTANTS.dataForCreditCard.category,
          GA_CONSTANTS.actions.click,
          GA_CONSTANTS.dataForCreditCard.labels.A7
        );
        break;
      case "transfer":
        setGAEvent(
          GA_CONSTANTS.dataForTransfer.category,
          GA_CONSTANTS.actions.click,
          GA_CONSTANTS.dataForTransfer.labels.B9
        );
        break;
      case "cash":
        setGAEvent(
          GA_CONSTANTS.dataForCash.category,
          GA_CONSTANTS.actions.click,
          GA_CONSTANTS.dataForCash.labels.C6
        );
        break;
    }
  };
  const handleSaveMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    getGAValues();
    setSavedSubscription(event.target.checked);
  };

  const getAmountsTransaction = (): AmountsTransaction => {
    const currency = get(
      props.webcheckout!.paymentConfig.amount,
      "currency",
      CurrencyEnum[get(props.webcheckout, "country", "Ecuador")]
    );

    const amount = formatAmount(totalAmount, currency, false);

    return {
      totalAmount: amount,
      currency,
    };
  };

  const validateBrandCharge = (data: object) => {
    const brand = get(props, "brandCard.brand");

    if (brand === CardBrandsEnum.VISA || brand === CardBrandsEnum.MASTERCARD) {
      setShowCardBrandingAnimation(true);
      props.initCardBrandingAnimation(
        brand,
        props.webcheckout!.id as string,
        data,
        onChargeSuccess,
        isMobile
      );
    } else {
      onChargeSuccess(data);
    }
  };

  const onChargeSuccess = (data: object) => {
    const amountsTransaction = getAmountsTransaction();

    const response: SuccessCharge = {
      amount: amountsTransaction.totalAmount,
      status: get(data, "details.transactionStatus"),
      ticketNumber: get(data, "ticketNumber"),
      products: defaultTo(props.webcheckout!.products, []),
      currency: amountsTransaction.currency,
      transactionReference: get(data, "transactionReference"),
      merchantId: get(props.webcheckout, "merchantId"),
    };

    setShowCardBrandingAnimation(false);

    history.push("/webcheckout/resume", {
      ...props.webcheckout,
      response,
      isActive,
      kind: "card",
    });
  };

  const onChargeCashSuccess = (data: object) => {
    const amountsTransaction = getAmountsTransaction();

    const response: SuccessCharge = {
      amount: amountsTransaction.totalAmount,
      ticketNumber: get(data, "ticketNumber"),
      products: defaultTo(props.webcheckout!.products, []),
      currency: amountsTransaction.currency,
      cashPinUrl: get(data, "pdfUrl"),
    };
    history.push("/webcheckout/resume", {
      ...props.webcheckout,
      response,
      kind: "cash",
    });
  };

  const onChargeAsyncSuccess = (data: object) => {
    window.open(get(data, "redirectUrl", ""), "_self");
  };

  const onChargeError = (error?: Error) => {
    const codeError = get(error, "code");
    history.push("/webcheckout/payment-declined", {
      id: props.webcheckout?.id,
      smartLinkUrl: props.webcheckout!.smartLinkUrl,
      kind: "card",
      webcheckout: {
        redirectURL: props.webcheckout!.redirectURL,
      },
      codeError,
      source: get(props.webcheckout, "kushkiMetadata.source", undefined),
    });
  };

  const onChargeAsyncError = (error?: Error) => {
    const codeError = get(error, "code");
    history.push("/webcheckout/payment-declined", {
      id: props.webcheckout?.id,
      smartLinkUrl: props.webcheckout!.smartLinkUrl,
      kind: "card-async",
      webcheckout: {
        redirectURL: props.webcheckout!.redirectURL,
      },
      codeError,
      source: get(props.webcheckout, "kushkiMetadata.source", undefined),
    });
  };

  const onChargeCashError = () => {
    history.push("/webcheckout/payment-declined", {
      id: props.webcheckout?.id,
      smartLinkUrl: props.webcheckout!.smartLinkUrl,
      kind: "cash",
      source: get(props.webcheckout, "kushkiMetadata.source", undefined),
    });
  };

  const handleCardCharge = (siftScienceDetails?: ISiftForm) => {
    const expDate: string[] = String(getValues("dateExp")).split("/");
    const emailContactDetail: string | undefined = get(
      props,
      "webcheckout.contactDetail.email",
      undefined
    );

    const commissionAmount = get(commission, "totalAmount", 0);

    const tokenRequest: TokenRequest | TokenKPayRequest =
      paymentMethodForm === "cardSaved"
        ? {
            walletId: walletId,
            authorization: localStorage.getItem("jwt") as string,
            amount: totalAmount - commissionAmount,
            months: isActive ? Number(currentValues.months) : undefined,
            currency: get(
              props.webcheckout!.paymentConfig.amount,
              "currency",
              CurrencyEnum[get(props.webcheckout, "country", "Ecuador")]
            ),
            isDeferred: isActive,
            cvv: getValues("cvv"),
          }
        : {
            card: {
              cvc: String(getValues("cvv")),
              expiryMonth: expDate[0],
              expiryYear: expDate[1],
              name: String(getValues("name")),
              number: String(getValues("cardNumber")).replace(/\s/g, ""),
            },
            isDeferred: isActive,
            amount: totalAmount - commissionAmount,
            months: isActive ? Number(currentValues.months) : undefined,
            currency: get(
              props.webcheckout!.paymentConfig.amount,
              "currency",
              CurrencyEnum[get(props.webcheckout, "country", "Ecuador")]
            ),
          };

    const webcheckoutEmail =
      props.webcheckoutEmail || String(getValues("email"));

    const publicMerchantId: string = get(
      props.webcheckout,
      "publicMerchantId",
      ""
    );

    const handleToken = (data: TokenResponse | ErrorResponse) => {
      const savePaymentData: boolean = getValues("savePaymentMethod");
      if (has(data, "token")) {
        if (has(data, "secureId")) {
          if (
            get(data, "secureService", "") === "3dsecure" &&
            has(data, "security")
          ) {
            if (get(data, "security.authRequired")) {
              props.validate3Ds(
                publicMerchantId,
                data as TokenResponse,
                handleValidate3Ds,
                onValidateError
              );
            } else {
              const savePaymentData: boolean = getValues("savePaymentMethod");
              const webcheckoutEmail =
                props.webcheckoutEmail || String(getValues("email"));
              const body = buildChargeRequest(
                data as TokenResponse,
                {
                  ...currentValues,
                  isActive,
                },
                savePaymentData,
                props.webcheckout,
                webcheckoutEmail,
                undefined,
                undefined,
                undefined,
                webcheckoutEmail
              );

              props.charge(
                props.webcheckout!.id as string,
                body,
                validateBrandCharge,
                onChargeError
              );
            }
          } else {
            setShowOTP(true);
            setToken(data as TokenResponse);
          }
        } else {
          const phoneNumber = get(
            props,
            "webcheckout.billingDetails.phoneNumber",
            null
          );

          if (!isNil(phoneNumber))
            set(props, "webcheckout.billingDetails.phone", phoneNumber);

          const body = buildChargeRequest(
            data as TokenResponse,
            {
              ...currentValues,
              isActive,
            },
            savePaymentData,
            props.webcheckout,
            webcheckoutEmail,
            emailContactDetail,
            siftScienceDetails,
            paymentMethodForm === "cardSaved" ? walletId : undefined,
            webcheckoutEmail
          );
          props.charge(
            props.webcheckout!.id as string,
            body,
            validateBrandCharge,
            onChargeError
          );
        }
      } else {
        history.push("/webcheckout/payment-declined", {
          id: props.webcheckout!.id,
          smartLinkUrl: props.webcheckout!.smartLinkUrl,
          kind: "card",
        });
      }
    };

    props.getToken(publicMerchantId, tokenRequest, handleToken);
  };

  const handleCardOnSubmit = () => handleCardCharge();

  const handleCardAsyncOnSubmit = () => {
    const publicMerchantId: string = get(
      props.webcheckout,
      "publicMerchantId",
      ""
    );
    const paymentMethod = "cardAsync";

    const amountsTransaction = getAmountsTransaction();

    const infoData: ParamsTransfer = {
      amount: amountsTransaction.totalAmount,
      currency: get(
        props.webcheckout!.paymentConfig.amount,
        "currency",
        CurrencyEnum[country]
      ),
      merchantName: get(props.webcheckout, "merchantName", ""),
      customerName: getValues("name"),
      email: getValues("email").toLowerCase(),
      bankName: getBankName(),
      date: format(new Date(), "yy-MM-dd"),
      documentNumber: getValues("identification"),
      products: defaultTo(props.webcheckout!.products, []),
      redirectUrl: get(props.webcheckout, "redirectURL", "/"),
      id: get(props.webcheckout, "id", "/"),
      transactionType: get(props.webcheckout, "transactionType"),
    };

    const tokenRequest: CardAsyncTokenRequest = {
      totalAmount: getTotalAmount(
        get(props.webcheckout!.paymentConfig, "amount")
      ),
      returnUrl: buildCallbackUrl(
        transferProcessorName,
        infoData,
        get(props.webcheckout, "kushkiMetadata.source", ""),
        paymentMethod
      ).href,
      currency: get(
        props.webcheckout!.paymentConfig.amount,
        "currency",
        CurrencyEnum[get(props.webcheckout, "country", "Ecuador")]
      ),
      email: getValues("email").toLowerCase(),
    };

    const handleCardAsyncToken = (
      tokenResponse: CardAsyncTokenResponse | ErrorResponse
    ) => {
      const email = getValues("email").toLowerCase();

      if (has(tokenResponse, "token")) {
        const body = buildChargeRequest(
          tokenResponse as TokenResponse,
          {
            ...currentValues,
            isActive,
          },
          false,
          props.webcheckout,
          email,
          undefined,
          undefined,
          undefined,
          email,
          paymentMethod
        );
        props.chargeAsync(
          props.webcheckout!.id as string,
          body,
          onChargeAsyncSuccess,
          onChargeAsyncError
        );
      } else {
        {
          history.push("/webcheckout/payment-declined", {
            id: props.webcheckout!.id,
            smartLinkUrl: props.webcheckout!.smartLinkUrl,
            kind: "card-async",
          });
        }
      }
    };

    props.getCardAsyncToken(
      publicMerchantId,
      tokenRequest,
      handleCardAsyncToken
    );
  };

  const handleCardSavedSelected = (idCard: string) => {
    setSavedcardId(idCard);
  };

  const onValidateError = () => {
    history.push("/webcheckout/payment-declined", {
      id: props.webcheckout?.id,
      smartLinkUrl: props.webcheckout!.smartLinkUrl,
      kind: "3ds",
      webcheckout: {
        redirectURL: props.webcheckout!.redirectURL,
      },
    });
  };

  const handleValidate3Ds = (
    response: ErrorResponse | Validate3DsResponse,
    data: TokenResponse
  ) => {
    const savePaymentData: boolean = getValues("savePaymentMethod");
    const webcheckoutEmail =
      props.webcheckoutEmail || String(getValues("email"));
    const body = buildChargeRequest(
      data as TokenResponse,
      {
        ...currentValues,
        isActive,
      },
      savePaymentData,
      props.webcheckout,
      webcheckoutEmail,
      undefined,
      undefined,
      undefined,
      webcheckoutEmail
    );

    props.charge(
      props.webcheckout!.id as string,
      body,
      validateBrandCharge,
      onChargeError
    );
  };

  const handleTransferOnSubmit = () => {
    setGAEvent(
      GA_CONSTANTS.dataForTransfer.category,
      GA_CONSTANTS.actions.click,
      GA_CONSTANTS.dataForTransfer.labels.B10
    );
    let bankName: string = getBankName();
    const publicMerchantId: string = get(
      props.webcheckout,
      "publicMerchantId",
      ""
    );
    const id_bank: string =
      country === CountryEnum.COL &&
      transferProcessorName === ProcessorsEnum.PSE
        ? getValues("bank")
        : undefined;

    const amountsTransaction = getAmountsTransaction();

    const infoData: ParamsTransfer = {
      amount: amountsTransaction.totalAmount,
      currency: get(
        props.webcheckout!.paymentConfig.amount,
        "currency",
        CurrencyEnum[country]
      ),
      merchantName: get(props.webcheckout, "merchantName", ""),
      customerName: getValues("name"),
      email: getValues("email").toLowerCase(),
      bankName: bankName,
      date: format(new Date(), "yy-MM-dd"),
      documentNumber: getValues("identification"),
      products: defaultTo(props.webcheckout!.products, []),
      redirectUrl: get(props.webcheckout, "redirectURL", "/"),
      id: get(props.webcheckout, "id", "/"),
    };

    const token: TransferTokenRequest = {
      bankId:
        country === CountryEnum.COL &&
        transferProcessorName === ProcessorsEnum.SAFETY_PAY_TRANSFER
          ? "0000"
          : id_bank,
      callbackUrl: buildCallbackUrl(
        transferProcessorName,
        infoData,
        get(props.webcheckout, "kushkiMetadata.source", "")
      ).href,
      documentNumber: getValues("identification"),
      documentType: getValues("identificationType"),
      email: getValues("email").toLowerCase(),
      paymentDescription: getValues("description"),
      amount: get(props.webcheckout, "paymentConfig.amount"),
      name: getValues("name"),
      currency: get(
        props.webcheckout!.paymentConfig.amount,
        "currency",
        CurrencyEnum[country]
      ),
    };

    if (country !== CountryEnum.CHL) set(token, "userType", getValues("type"));

    const handleTransferToken = (
      tokenResponse: TokenResponse | ErrorResponse
    ) => {
      if (has(tokenResponse, "token")) {
        const savePaymentData: boolean = getValues("savePaymentMethod");
        const fullName: string[] = String(getValues("name"))
          .trim()
          .split(" ")
          .filter((name: string) => name !== "");
        const data: ChargesRequest = {
          activationMethod: "transferPayment",
          channel: getChannel(props.webcheckout),
          metadata: buildMetadata(props.webcheckout),
          contactDetails: {
            documentNumber: getValues("identification"),
            documentType: getValues("identificationType"),
            email: getValues("email"),
            firstName: getValues("name") === undefined ? "" : fullName[0],
            lastName: getValues("name") === undefined ? "" : fullName[1],
          },
          token: get(tokenResponse, "token", ""),
          paymentDataEmail: savePaymentData
            ? defaultTo(props.webcheckoutEmail, getValues("email"))
            : undefined,
        };

        props.charge(
          props.webcheckout!.id as string,
          data,
          onChargeTransferSuccess,
          onChargeError
        );
      } else {
        {
          history.push("/webcheckout/payment-declined", {
            id: props.webcheckout!.id,
            smartLinkUrl: props.webcheckout!.smartLinkUrl,
            kind: "transfer",
          });
        }
      }
    };

    props.getTransferToken(publicMerchantId, token, handleTransferToken);
  };

  const onChargeTransferSuccess = (chargeResponse: object) => {
    if (has(chargeResponse, "redirectUrl")) {
      window.open(get(chargeResponse, "redirectUrl", ""), "_self");
    } else if (
      has(chargeResponse, "referenceProcessor") &&
      has(chargeResponse, "referenceNumber")
    ) {
      const amountsTransaction = getAmountsTransaction();

      const response: SuccessCharge = {
        referenceProcessor: get(chargeResponse, "referenceProcessor"),
        ticketNumber: get(chargeResponse, "ticketNumber"),
        currency: get(chargeResponse, "details.amount.currency"),
        amount: amountsTransaction.totalAmount,
        bankName: get(chargeResponse, "bank"),
        transactionReference: get(chargeResponse, "transactionReference"),
        merchantId: props.webcheckout!.merchantId,
      };
      history.push("/webcheckout/resume", {
        ...props.webcheckout,
        response,
        kind: "stp",
      });
    }
  };

  const handleOtpRetry = () => {
    setGAEvent(
      GA_CONSTANTS.otp.category,
      GA_CONSTANTS.actions.click,
      GA_CONSTANTS.otp.labels.A10
    );
    setToken(undefined);
    setPaymentMethodForm("card");
    setShowOptionMethod(false);
  };

  const handleRetryPayment = () => {
    setGAEvent(
      GA_CONSTANTS.dataValidation.category,
      GA_CONSTANTS.actions.click,
      GA_CONSTANTS.dataValidation.labels.E1
    );
    setOtpAttempts(0);
    setOtpError(false);
    setToken(undefined);
    setShowOTP(false);
    setPaymentMethodForm("card");
    setShowOptionMethod(false);
    hidePasswordless();
  };

  const handleAcceptTerms = () => {
    const category =
      paymentMethodForm === "cash"
        ? GA_CONSTANTS.dataForCash.category
        : GA_CONSTANTS.dataForTransfer.category;
    const label =
      paymentMethodForm === "cash"
        ? GA_CONSTANTS.dataForCash.labels.C5
        : GA_CONSTANTS.dataForTransfer.labels.B8;
    setGAEvent(category, GA_CONSTANTS.actions.click, label);
    setDisablePay(!disablePay);
  };

  const handleOtpValidationResponse = (
    response: SecureOtpResponse | ErrorResponse
  ) => {
    const savePaymentData: boolean = getValues("savePaymentMethod");
    const webcheckoutEmail =
      props.webcheckoutEmail || String(getValues("email"));

    const smartLinkId: string = get(params, "smartlinkId");
    if (get(response, "message") === "ok") {
      setOtpInvalid(false);
      const body = buildChargeRequest(
        token as TokenResponse,
        {
          ...currentValues,
          isActive,
        },
        savePaymentData,
        props.webcheckout,
        webcheckoutEmail,
        undefined,
        undefined,
        undefined,
        webcheckoutEmail
      );
      setShowOTP(false);
      props.charge(smartLinkId, body, validateBrandCharge, onChargeError);
    } else {
      if (otpAttempts + 1 <= 2) {
        setOtpAttempts(otpAttempts + 1);
      }
      setOtpInvalid(true);
    }
  };

  const handleOtpCheck = (value: string) => {
    const publicMerchantId: string = get(
      props.webcheckout,
      "publicMerchantId",
      ""
    );
    const secureOtpRequest: SecureOtpRequest = {
      secureService: get(token, "secureService"),
      otpValue: value,
      secureServiceId: get(token, "secureId", ""),
    };
    props.secureServiceValidation(
      publicMerchantId,
      secureOtpRequest,
      handleOtpValidationResponse
    );
  };

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectTab(newValue);
  };

  const handleOpenCollapse = () => {
    setOpenCollapse(!openCollapse);
  };

  const handleCashOnSubmit = () => {
    setGAEvent(
      GA_CONSTANTS.dataForCash.category,
      GA_CONSTANTS.actions.click,
      GA_CONSTANTS.dataForCash.labels.C7
    );
    const fullName: string[] = (getValues("name") as string).split(" ");

    const commissionAmount = get(commission, "totalAmount", 0);

    const cashToken: CashTokenRequest = {
      name: fullName[0],
      lastName: fullName[1],
      currency: get(
        props.webcheckout!.paymentConfig.amount,
        "currency",
        CurrencyEnum[get(props.webcheckout, "country", "Ecuador")]
      ),
      documentType: getValues("identificationType"),
      identification: getValues("identification"),
      totalAmount: totalAmount - commissionAmount,
      email: getValues("email"),
    };
    const publicMerchantId: string = get(
      props.webcheckout,
      "publicMerchantId",
      ""
    );
    if (!cashToken.lastName) cashToken.lastName = "";
    const handleToken = (data: TokenResponse | ErrorResponse) => {
      if (has(data, "token")) {
        const body = buildCashCharge(data as TokenResponse);
        props.charge(
          props.webcheckout!.id as string,
          body,
          onChargeCashSuccess,
          onChargeCashError
        );
      }
    };
    props.getCashToken(publicMerchantId, cashToken, handleToken);
  };

  const buildCashCharge = (token: TokenResponse): ChargesRequest => {
    const fullName: string[] = (getValues("name") as string)
      .trim()
      .split(" ")
      .filter((word: string) => word !== "");
    const savePaymentData: boolean = getValues("savePaymentMethod");

    const charge: ChargesRequest = {
      token: get(token, "token", ""),
      paymentMethod: "cash",
      channel: getChannel(props.webcheckout),
      activationMethod: "cashPayment",
      metadata: {
        firstTime: savedSubscription ? "1" : "0",
        ...buildMetadata(props.webcheckout),
      },
      paymentDataEmail: savePaymentData
        ? defaultTo(props.webcheckoutEmail, getValues("email"))
        : undefined,
      contactDetails: {
        documentNumber: getValues("identification"),
        documentType: getValues("identificationType"),
        email: getValues("email"),
        firstName: fullName[0],
        lastName: !fullName[1] ? "" : fullName[1],
      },
    };
    return charge;
  };

  const handleTransferSubscriptionToken = (
    response: TokenResponse | ErrorResponse
  ) => {
    const onChargeSuccess = (data: any) =>
      history.push(`/webcheckout/${params.smartlinkId}/speiResume`, {
        totalAmount,
        currency,
        ...data,
      });
    const onError = () => {
      history.push("/webcheckout/payment-declined", {
        smartLinkUrl: props.webcheckout!.smartLinkUrl,
        kind: "transfer-subscription",
        source: get(props.webcheckout, "kushkiMetadata.source", undefined),
      });
    };

    if (has(response, "token")) {
      const token = (response as TokenResponse).token;
      const body: ChargesRequest = {
        token,
        paymentMethod: "transfer",
        activationMethod: "transferPayment",
        metadata: {},
      };
      props.charge(params.smartlinkId, body, onChargeSuccess, onError);
    }
  };

  const handleTransferSubscriptionOnSubmit = () => {
    const tokenRequest: TransferSubscriptionTokenRequest = {
      email: getValues("email"),
    };
    props.getTransferSubscriptionToken(
      get(props.webcheckout, "publicMerchantId", ""),
      tokenRequest,
      handleTransferSubscriptionToken
    );
  };

  const getOnSubmitFormHandler = (): (() => void) => {
    switch (paymentMethodForm) {
      case "card":
        return handleCardOnSubmit;
      case "transfer":
        return handleTransferOnSubmit;
      case "cash":
        return handleCashOnSubmit;
      case "cardSaved":
        return handleCardOnSubmit;
      case "cardAsync":
        return handleCardAsyncOnSubmit;
      case "card-dynamic":
        return cardType === "debit"
          ? handleCardAsyncOnSubmit
          : handleCardOnSubmit;
      default:
        return () => console.warn("undefined onSubmit");
    }
  };

  const handleDeletePayMethod = async (id: string) => {
    const jwt: string | null = localStorage.getItem("jwt");
    setEventType(EventTypeEnum.REMOVE);

    if (jwt === null)
      await initAuth(
        {
          email: props.webcheckoutEmail!,
          merchantName: get(props.webcheckout, "merchantName"),
          firstName: get(props.webcheckout, "contactDetail.name"),
        },
        () => {
          hidePasswordless();
          setPayMethodIdSelected(id);
          setOpenConfirmMessage(true);
        }
      );
    else {
      setPayMethodIdSelected(id);
      setOpenConfirmMessage(true);
    }
  };

  const handleCancel = () => {
    setPayMethodIdSelected(undefined);
    setOpenConfirmMessage(false);
    setLoadingConfirm(false);
  };

  const handleConfirmDeletePayMethod = () => {
    const publicMerchantId: string = get(
      props.webcheckout,
      "publicMerchantId",
      ""
    );
    if (!isEmpty(payMethodIdSelected)) {
      setLoadingConfirm(true);
    }
  };

  const isValidDate = (expDate: string) => {
    const [stringMonth, stringYear] = expDate.split("/");
    const monthDate: number = Number(stringMonth);
    const millennium = new Date().getFullYear() / 1000;
    const millenniumValue = Math.floor(millennium) * 1000;
    const yearDate: number = millenniumValue + Number(stringYear);

    if (monthDate > 12 || monthDate < 1)
      return (<T id="form.invalidDate" />) as never;

    return new Date() > new Date(yearDate, monthDate)
      ? ((<T id="form.expirationDateIncorrect" />) as never)
      : true;
  };

  const isValidCVV = (cvv: string) => {
    return cvv.length < 3 ? ((<T id="form.cvcInvalid" />) as never) : true;
  };

  const getBankName = () => {
    let bankName = "";
    get(props, "banks", []).find((bank: BankItem) => {
      if (bank.code === getValues("bank")) bankName = bank.name;
    });
    return bankName;
  };

  const filterBrandCard = (
    brands: {
      image: string;
      value: string;
    }[]
  ) =>
    brands
      .filter(
        (brand) =>
          brand.value.toLowerCase() === props.brandCard!.brand.toLowerCase()
      )
      .map((brand) => brand.image)[0];

  return {
    isMobile,
    disablePay,
    subtotal,
    buttonText,
    paymentMethodForm,
    openSavedCardForm,
    openTerms,
    userEmail,
    userName,
    paymentMethod: {
      isMobile,
      handleSelectMethod,
      showOptionMethod,
      logos: {
        card: brandsCard,
        transfer: brandsTransfer,
        cash: brandsCash,
      },
      methodsActive,
      paymentSubscriptions: paymentData,
      handlePaySavedMethod,
      handleDeletePayMethod,
      handleCardSavedSelected,
      labelCreditCardPaymentMethod,
    },
    handler: {
      handleChangePaymentMethod,
      handleOpenTermsConditions,
      handleSetFields,
      handleChangeTab,
      handleOpenCollapse,
      handleAcceptTerms,
      handleRetryPayment,
      handleSaveMethod,
    },
    form: {
      errors,
      register,
      handleSubmit,
      handleOnSubmitForm: getOnSubmitFormHandler(),
      control,
      currentValues,
      getValues,
      reset,
    },
    transferForm: {
      identificationType: identificationNumberType,
    },
    webcheckout: {
      totalAmount,
      logos: {
        cardBrands: brandsCard,
        cashBrands: brandsCash,
        transferBrands: brandsTransfer,
      },
      brandCardInput,
      cardType,
      color,
      secondaryColor,
      buttonStyle,
      paymentConfig,
      products,
      commission,
      metadata,
      country,
      transferProcessorName,
      paymentDescription,
      webcheckoutType,
      redirectURL: props.webcheckout?.redirectURL,
    },
    deferredOptions: {
      hasDeferred,
      isDynamic,
      typesDeferred,
      months,
      monthsOfGrace,
      isActive,
    },
    selectTab,
    openCollapse,
    captureOtp: {
      color,
      handleRetryPayment,
      openOtpError,
      handleCheck: handleOtpCheck,
      handleRetry: handleOtpRetry,
      showOTP,
      isOtpInvalid: otpInvalid,
    },
    capturePasswordless,
    cardSavedPaymentData: cardSavedPaymentData!,
    cashSavedPaymentData: cashSavedPaymentData,
    transferSavedPaymentData: transferSavedPaymentData,
    confirmMessage: {
      loading: loadingConfirm,
      open: openConfirmMessage,
      handleSecondaryButton: handleCancel,
      handleOnClose: handleCancel,
      handlePrimaryButton: handleConfirmDeletePayMethod,
    },
    eventType,
    siftscienceForm: {
      open: openSiftscienceForm,
      setOpen: setOpenSiftscienceForm,
      form: siftscienceForm,
      handleOnSubmitForm: handleCardCharge,
    },
    savedCardId,
    isValidDate,
    isValidCVV,
    showCardBrandingAnimation,
    isLoadingProps,
    showMonthlyPaymentMessage,
  };
};
